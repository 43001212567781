const initialState = {
  selectedAttributes: {},
};

export default (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'rewriteSelectedAttributes':
      return {
        ...state,
        selectedAttributes: {
          ...rest,
        },
      };
    case 'setSelectedAttributes':
      return {
        ...state,
        selectedAttributes: {
          ...state.selectedAttributes,
          ...rest,
        },
      };
    case 'clearSelectedAttributes':
      return {
        ...state,
        selectedAttributes: {},
      };
    default:
      return state;
  }
};
