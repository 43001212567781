import React, { useState, useMemo } from 'react';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Table } from 'components';
import LoadingSpinner from 'components/LoadingSpinner';
import { BasicButton } from 'components/buttons';
import { MenuDropdown } from 'components/dropdowns';
import { useUsersApi } from 'hooks/api';
import { useToken } from 'hooks';
import redefineIcon from 'assets/icons/redefine.svg';
import endpoints from 'const/endpoints';
import dotsIcon from 'assets/icons/3dots.svg';

import './style.scss';

const defaultOffset = 1;

const ReportBlock = ({
  report,
  setEditableReport,
  setActiveTab,
  postCreateReport,
  isLoading,
}) => {
  const buttonStyle = { minWidth: 50 };

  const { t } = useTranslation();
  const { currentUser } = useUsersApi();
  const { token } = useToken();
  const [limit, setLimit] = useState(report.limit);
  const [offset, setOffset] = useState(
    report.offset ? (report.offset / report.limit) + 1 : defaultOffset,
  );
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadPermission = useMemo(() =>
    currentUser?.roles?.includes?.('reports-export-results'), []);

  const dropdownClass = cx('report-block__dropdown', {
    'report-block__dropdown_disabled': !downloadPermission,
  });

  const onPressDownloadFile = (type) => {
    setIsDownloading(true);
    const oReq = new XMLHttpRequest();
    oReq.open('POST', endpoints.getReportFileUrl(), true);
    oReq.responseType = 'blob';
    oReq.setRequestHeader('Authorization', `Bearer ${token}`);

    oReq.send(JSON.stringify({
      name: report.name,
      templateId: report.templateId,
      parameters: report.initialParameters,
      type,
    }));

    oReq.onload = () => {
      setIsDownloading(false);
      saveAs(
        oReq.response,
        `${report.name}.${type}`,
      );
    };
  };

  const getReport = (newLimit, newOffset) => {
    let valuesModify = {};

    Object.keys(report.parameters).forEach((key) => {
      valuesModify = {
        ...valuesModify,
        [key]: report.parameters[key] || '',
      };
    });

    postCreateReport({
      body: {
        name: report.name,
        params: valuesModify,
        templateId: report.templateId,
        limit: newLimit,
        offset: newOffset ? ((newOffset - 1) * newLimit) : newOffset,
      },
    });
  };

  const onRefineClick = () => {
    setEditableReport({
      name: report.name,
      templateId: report.templateId,
      initialValues: { ...report.parameters },
    });
    setActiveTab('control');
  };

  const onChangeLimit = (newLimit) => {
    setLimit(newLimit);
    setOffset(defaultOffset);
    getReport(newLimit, defaultOffset);
  };

  const onChangeOffset = (newOffset) => {
    setOffset(newOffset);
    getReport(limit, newOffset);
  };

  return (
    <Table
      rowData={report.list}
      columnDefs={report.columns}
      title={report.name}
      limit={limit}
      total={report.size}
      offset={offset}
      resizableColDef
      onChangeLimit={onChangeLimit}
      onChangePage={onChangeOffset}
      loading={isLoading}
      onRefreshPress={() => getReport(report.limit, report.offset)}
      rightComponent={(
        <>
          <BasicButton
            icon={redefineIcon}
            onClick={onRefineClick}
            text={t('SCREENS.REPORTS.REFINE')}
            style={buttonStyle}
          />
          {
            isDownloading
              ? (
                <>
                  <LoadingSpinner small />
                  <span className="report-block__loading-label">{t('STATES.LOADING')}</span>
                </>
              )
              : (
                <MenuDropdown
                  menu={[
                    {
                      text: t('CONTROLS.DOWNLOAD_FORMAT', { format: 'CSV' }),
                      action: () => onPressDownloadFile('csv'),
                    },
                    {
                      text: t('CONTROLS.DOWNLOAD_FORMAT', { format: 'XLS' }),
                      action: () => onPressDownloadFile('xls'),
                    },
                    {
                      text: t('CONTROLS.DOWNLOAD_FORMAT', { format: 'XLSX' }),
                      action: () => onPressDownloadFile('xlsx'),
                    },
                  ]}
                  disabled={!downloadPermission}
                >
                  <img
                    className={dropdownClass}
                    src={dotsIcon}
                    alt=""
                    title={downloadPermission ? undefined : 'Not enough permissions'}
                  />
                </MenuDropdown>
              )
          }
        </>
      )}
    />
  );
};

export default ReportBlock;
