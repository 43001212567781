const WHATSAPP_IMAGE_EXTENSIONS = ['png', 'jpeg', 'jpg'];
const WHATSAPP_AUDIO_EXTENSIONS = ['aac', 'mp4', 'mpeg'];
const WHATSAPP_VIDEO_EXTENSIONS = ['mp4', '3gp'];
const WHATSAPP_DOCUMENT_EXTENSIONS = ['pdf'];
const WHATSAPP_ALL_EXTENSIONS = ['pdf', 'jpeg', 'mpeg', 'mp4'];
const RCS_ALL_EXTENSIONS = ['jpeg', 'png', 'gif', 'jpg', 'mp4', 'mpeg', 'webm'];
const VIBER_ALL_EXTENSIONS = ['jpeg', 'png', 'jpg', 'doc', 'docx', 'rtf', 'dot', 'dotx', 'odt', 'odf', 'fodt', 'txt', 'info'];
const WECHAT_IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif'];
const VIBER_VIDEO_MIME_TYPES = ['video/x-msvideo', 'video/wmv', 'video/mov', 'video/mp4', 'video/m4v', 'video/3gpp', 'video/3gpp2', 'video/asf', 'video/mkv', 'video/mp2ts', 'video/webm'];
const VIBER_DOCUMENT_MIME_TYPES = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/rtf', 'application/dot', 'application/dotx', 'application/vnd.oasis.opendocument.text', 'application/odf', 'application/fodt', 'text/plain', 'application/info', 'application/pdf', 'application/xps', 'application/pdax', 'application/eps', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/ods', 'application/fods', 'text/csv', 'application/xlsm', 'application/xltx'];
const VIBER_ALL_MIME_TYPES = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif', 'image/svg+xml', 'image/webp', 'video/x-msvideo', 'video/wmv', 'video/mov', 'video/mp4', 'video/m4v', 'video/3gpp', 'video/3gpp2', 'video/asf', 'video/mkv', 'video/mp2ts', 'video/webm', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/rtf', 'application/dot', 'application/dotx', 'application/vnd.oasis.opendocument.text', 'application/odf', 'application/fodt', 'text/plain', 'application/info', 'application/pdf', 'application/xps', 'application/pdax', 'application/eps', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/ods', 'application/fods', 'text/csv', 'application/xlsm', 'application/xltx'];
const WHATSAPP_IMAGE_MIME_TYPES = ['image/png', 'image/jpeg'];
const WHATSAPP_AUDIO_MIME_TYPES = ['audio/aac', 'video/mp4', 'video/mpeg'];
const WHATSAPP_VIDEO_MIME_TYPES = ['video/mp4', 'video/3gpp'];
const WHATSAPP_DOCUMENT_MIME_TYPES = ['application/pdf', 'application/pdf'];
const WHATSAPP_ALL_MIME_TYPES = ['application/pdf', 'image/png', 'image/jpeg', 'video/mp4', 'video/mpeg'];
const RCS_ALL_MIME_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/jpeg', 'video/mp4', 'video/mpeg', 'video/webm'];
const VIBER_IMAGE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif', 'image/svg+xml', 'image/webp'];
const WECHAT_IMAGE_MIME_TYPES = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
export {
  WHATSAPP_IMAGE_EXTENSIONS,
  WHATSAPP_AUDIO_EXTENSIONS,
  WHATSAPP_VIDEO_EXTENSIONS,
  WHATSAPP_DOCUMENT_EXTENSIONS,
  WHATSAPP_ALL_EXTENSIONS,
  RCS_ALL_EXTENSIONS,
  VIBER_ALL_EXTENSIONS,
  VIBER_ALL_MIME_TYPES,
  RCS_ALL_MIME_TYPES,
  WHATSAPP_ALL_MIME_TYPES,
  VIBER_IMAGE_MIME_TYPES,
  VIBER_VIDEO_MIME_TYPES,
  VIBER_DOCUMENT_MIME_TYPES,
  WHATSAPP_IMAGE_MIME_TYPES,
  WHATSAPP_AUDIO_MIME_TYPES,
  WHATSAPP_VIDEO_MIME_TYPES,
  WHATSAPP_DOCUMENT_MIME_TYPES,
  WECHAT_IMAGE_EXTENSIONS,
  WECHAT_IMAGE_MIME_TYPES,
};
