import React, {
  useState,
  useMemo,
  useReducer,
  useEffect,
} from 'react';
import { useToggle } from 'react-use';
import { cloneDeep, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { ConstructorEditor as ConstructorEditorNew } from 'mkit-front-ds/dist/components/constructor';
import { toast as toastClass } from 'react-toastify';

import {
  useSendersApi,
  useRecipientsApi,
  useWhatsAppTemplatesApi,
  useTemplatesApi,
} from 'hooks/api';
import {
  DropdownField,
  TimePickerField,
  DropdownSpecifyField,
} from 'components/fields';
import {
  WHATSAPP_ALL_EXTENSIONS,
  WHATSAPP_AUDIO_EXTENSIONS,
  WHATSAPP_DOCUMENT_EXTENSIONS,
  WHATSAPP_IMAGE_EXTENSIONS,
  WHATSAPP_VIDEO_EXTENSIONS,
} from 'const';
import { BasicButton, IconButton } from 'components/buttons';
import { ConstructorEditor, SendersDropdown } from 'components/constructor';
import {
  GeolocationForm,
  CreateActionForm,
} from 'components/forms';
import LoadingSpinner from 'components/LoadingSpinner';
import Drawer from 'components/UIKit/Drawer/Drawer';
import SelectMedia from 'components/SelectMedia/SelectMedia';
import {
  convertObjectTimeToSeconds,
  toast,
} from 'helpers';
import crossIcon from 'assets/icons/cross-grey.svg';
import disketteIcon from 'assets/icons/diskette.svg';
import arrowLeftIcon from 'assets/icons/left-arrow.svg';
import disketteDarkIcon from 'assets/icons/diskette-dark.svg';
import trashIcon from 'assets/icons/trash.svg';
import { error } from 'helpers/toast';
import './style.scss';

const initialWhatsAppParams = {
  selectedType: '',
  message: '',
};

const WhatsAppBlock = ({
  channel,
  onChange,
  onCancel,
  index,
  senderId,
  initialParams,
  withTools = true,
  isNewDesign = false,
  readOnly,
  deliveryCondition = 'DELIVERY_SUCCESS',
  constructorType = 'freeMessage',
  timeout = { hours: '00', minutes: '10', seconds: '00' },
  withShortLink,
  setShortLinks,
  channelNumber,
}) => {
  const { t } = useTranslation();
  const timeFieldStyle = {
    container: {
      width: '100%',
    },
    overlay: {
      top: 'unset',
      bottom: 36,
    },
  };
  const messageTypes = [
    {
      id: 1,
      value: 'freeMessage',
      title: t('MESSAGE_CONSTRUCTOR.FREE_MESSAGE'),
    },
    {
      id: 2,
      value: 'template',
      title: t('MESSAGE_CONSTRUCTOR.TEMPLATE'),
    },
  ];

  const headerButtonStyle = { minWidth: 20 };

  const {
    getSenders,
    senders,
    isPendingGetSenders,
  } = useSendersApi();
  const {
    getTemplates,
    templates,
    isPendingGetTemplates,
    postCreateTemplate,
    patchUpdateTemplate,
    deleteTemplate,
  } = useTemplatesApi();

  const { getApprovedTemplates, approvedTemplates } = useWhatsAppTemplatesApi();
  const { getAttributes, isPendingGetAttributes } = useRecipientsApi();

  const [sender, setSender] = useState(senderId || '');
  const [waitFor, setWaitFor] = useState(deliveryCondition);
  const [waitingTime, setWaitingTime] = useState(timeout);
  const [showError, setShowError] = useState(false);
  const [messageType, setMessageType] = useState(constructorType === 'template' ? { value: 2, isCustom: false } : { value: 1, isCustom: false });
  const [freeMessage, setFreeMessage] = useState('');
  const [approvedTemplate, setApprovedTemplate] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectMediaOpen, toggleSelectOpenMedia] = useToggle(false);
  const [geolocationOpen, toggleGeolocationOpen] = useToggle(false);
  const [createSuggestion, setCreateSuggestion] = useState({ open: false, place: '' });

  const wrapperClass = classNames('whats-app-block__content-wrapper', {
    'whats-app-block__content-wrapper_new-design': isNewDesign,
    alwaysLightTheme: true,
  });

  const wrapperHeaderClass = classNames('whats-app-block__content-wrapper__header', {
    'whats-app-block__content-wrapper__header_new-design': isNewDesign,
  });

  const reducer = (state, { isReset, ...newValue }) => {
    if (isReset) {
      return newValue;
    }

    return ({
      ...state,
      ...newValue,
    });
  };
  const [rcsParams, updateRcsParams] = useReducer(reducer, initialParams || {
    suggestionsInside: [],
  });

  const mediaFormat = useMemo(() => {
    if (rcsParams.components?.length) {
      const headerFormat = rcsParams.components.find(C => C.type === 'HEADER')?.format;

      switch (headerFormat) {
        case 'IMAGE': return 'image';
        case 'VIDEO': return 'video';
        case 'DOCUMENT': return 'document';
        case 'LOCATION': return 'location';
        default: return undefined;
      }
    }

    return undefined;
  }, [rcsParams.components]);

  const templateExtensions = useMemo(() => {
    if (messageType.value === 1) {
      return WHATSAPP_ALL_EXTENSIONS;
    }
    switch (mediaFormat) {
      case 'image':
        return WHATSAPP_IMAGE_EXTENSIONS;
      case 'video':
        return WHATSAPP_VIDEO_EXTENSIONS;
      case 'document':
      default:
        return WHATSAPP_DOCUMENT_EXTENSIONS;
    }
  }, [mediaFormat, messageType]);

  const sendersOptions = useMemo(() => senders.filter(I => I.channel === 4 && I.status === 2)
    .map(I => ({
      id: I.senderId,
      title: I.displayName,
    })), [senders]);
  const approvedTemplatesOptions = useMemo(() => approvedTemplates
    .filter(I => I.category !== 'AUTHENTICATION')
    .map(I => ({
      title: I.name,
      id: I.id,
      senderId: I.senderId,
    })), [approvedTemplates]);
  const templatesOptions = useMemo(() => templates.filter(I => I.channel === 4).map(I =>
    ({
      title: I.name,
      id: I.id,
    })), [templates]);
  const geolocationInitialValues = useMemo(() => ({
    latitude: rcsParams.location?.latitude || rcsParams.latitude,
    longitude: rcsParams.location?.longitude || rcsParams.longitude,
    name: rcsParams.location?.name || rcsParams.name,
    address: rcsParams.location?.address || rcsParams.address,
  }), [rcsParams.latitude, rcsParams.longitude, rcsParams.name, rcsParams.address, rcsParams.location]);
  const resetType = () => {
    if (initialParams) {
      updateRcsParams({ isReset: true, ...initialParams });
    } else {
      updateRcsParams({ isReset: true, ...initialWhatsAppParams });
    }
  };

  const transformMedia = (target) => {
    if (messageType.value === 1) {
      const rcsParamsCopy = { ...rcsParams };
      let whatsAppMediaType = 'document';
      const fileExtension = target.fileUrl.split('.').pop().toLowerCase();
      if (WHATSAPP_IMAGE_EXTENSIONS.includes(fileExtension)) {
        whatsAppMediaType = 'image';
        delete rcsParamsCopy.document;
        delete rcsParamsCopy.video;
        delete rcsParamsCopy.audio;
      }
      if (WHATSAPP_AUDIO_EXTENSIONS.includes(fileExtension)) {
        whatsAppMediaType = 'audio';
        delete rcsParamsCopy.image;
        delete rcsParamsCopy.video;
        delete rcsParamsCopy.document;
      }
      if (WHATSAPP_VIDEO_EXTENSIONS.includes(fileExtension)) {
        whatsAppMediaType = 'video';
        delete rcsParamsCopy.image;
        delete rcsParamsCopy.document;
        delete rcsParamsCopy.audio;
      }
      if (WHATSAPP_DOCUMENT_EXTENSIONS.includes(fileExtension)) {
        delete rcsParamsCopy.image;
        delete rcsParamsCopy.video;
        delete rcsParamsCopy.audio;
      }
      if (rcsParams.selectedType === 'whatsAppFile') {
        const newMedia = {
          ...rcsParamsCopy,
          type: whatsAppMediaType,
          [whatsAppMediaType]: {
            link: target.fileUrl,
          },
        };
        return {
          isReset: true,
          ...newMedia,
        };
      } if (rcsParams.selectedType === 'whatsAppCard') {
        const newMedia = {
          ...rcsParamsCopy,
          header: {
            type: whatsAppMediaType,
            [whatsAppMediaType]: {
              link: target.fileUrl,
            },
          },
        };
        return {
          isReset: true,
          ...newMedia,
        };
      }
    }
    return {
      image: target,
    };
  };

  const selectMedia = (image) => {
    const result = transformMedia(image);
    updateRcsParams(result);
  };
  const closeCreateSuggestion = () => {
    setCreateSuggestion({ ...createSuggestion, open: false });
  };
  const onCreateSuggestion = ({
    isEdit,
    id,
    suggestionType,
    ...values
  }) => {
    const updateRcsParamsModify = updateRcsParams;
    const rcsParamsModify = rcsParams;
    const text = 'Reply';

    if (createSuggestion.place === 'inside') {
      if (id !== undefined) {
        const editableSuggestionIndex = rcsParamsModify.suggestionsInside.indexOf(
          rcsParamsModify?.suggestionsInside?.find(I => I.id === id),
        );

        rcsParamsModify.suggestionsInside[editableSuggestionIndex] = ({
          id,
          text,
          suggestionType,
          ...values,
        });
      } else {
        rcsParamsModify.suggestionsInside = rcsParamsModify.suggestionsInside || [];
        updateRcsParamsModify({
          suggestionsInside: [
            ...rcsParamsModify.suggestionsInside,
            {
              id: rcsParamsModify.suggestionsInside.length,
              text,
              suggestionType,
              ...values,
            },
          ],
        });
      }
    }
    closeCreateSuggestion();
  };
  const openCreateSuggestion = ({ place, initialValues, isReply }) => {
    if (initialValues?.category === 'mapAction' && initialValues?.query) {
      initialValues.by = true;
    }
    setCreateSuggestion({
      open: true,
      place,
      initialValues,
      isReply,
    });
  };
  const onApply = () => {
    if (!sender && withTools) {
      setShowError(true);
    } else {
      let saveAllowed = true;
      setShowError(false);

      let contentPattern = {};

      switch (rcsParams.selectedType) {
        case 'whatsAppMenu':
          if (!rcsParams?.body?.text || !rcsParams?.action?.sections.length) {
            saveAllowed = false;
            toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_LIST_REQUIREMENTS'));
          }
          contentPattern = {
            recipient_type: 'individual',
            type: 'interactive',
            interactive: {
              type: 'list',
              ...rcsParams,
            },
          };
          break;
        case 'whatsAppText':
          if (messageType.value === 1) {
            if (rcsParams?.text?.body) {
              contentPattern = {
                preview_url: true,
                recipient_type: 'individual',
                type: 'text',
                text: {
                  body: rcsParams?.text?.body,
                },
              };
            } else {
              saveAllowed = false;
              toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_TEXT_REQUIREMENTS'));
            }
          } else {
            contentPattern = {
              preview_url: true,
              recipient_type: 'individual',
              type: 'text',
              text: {
                body: rcsParams.message,
              },
            };
          }
          break;
        case 'whatsAppFile': {
          if (messageType.value === 1) {
            if (!rcsParams?.image
                && !rcsParams?.document
                && !rcsParams?.video
                && !rcsParams?.audio
            ) {
              saveAllowed = false;
              toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_FILE_REQUIREMENTS'));
            } else {
              let type = 'document';
              if (rcsParams?.image) {
                type = 'image';
              }
              if (rcsParams?.video) {
                type = 'video';
              }
              if (rcsParams?.audio) {
                type = 'audio';
              }
              contentPattern = {
                type,
                ...rcsParams,
              };
            }
          } else {
            const mediaType = rcsParams.image.mediaType === 'Image' || rcsParams.image.mediaType === 'Video'
              ? rcsParams.image.mediaType.toLowerCase()
              : 'document';

            contentPattern = {
              recipient_type: 'individual',
              type: mediaType,
              [mediaType]: {
                link: rcsParams.image.fileUrl,
              },
            };
          }
          break;
        }
        case 'whatsAppCard': {
          if (rcsParams?.body?.text && (rcsParams?.suggestionsInside && rcsParams?.suggestionsInside.length)) {
            const rcsParamsCopy = { ...rcsParams };
            const copyOfSuggestions = rcsParamsCopy.suggestionsInside;
            const buttons = copyOfSuggestions.map(I => ({
              type: 'reply',
              reply: {
                id: `ID: ${I.id}`,
                title: I.text,
              },
            }));
            rcsParamsCopy.action = { buttons };
            delete rcsParamsCopy.suggestionsInside;

            contentPattern = {
              recipient_type: 'individual',
              type: 'interactive',
              interactive: {
                type: 'button',
                ...rcsParamsCopy,
              },
            };
          } else {
            saveAllowed = false;
            toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_CARD_REQUIREMENTS'));
          }
          break;
        }
        case 'whatsAppSendGeolocation':
          if (rcsParams?.body?.text) {
            contentPattern = {
              recipient_type: 'individual',
              type: 'interactive',
              interactive: {
                ...rcsParams,
              },
            };
          } else {
            saveAllowed = false;
            toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_TEXT_REQUIREMENTS'));
          }
          break;
        case 'whatsAppGetGeolocation':
          if (rcsParams?.location?.latitude && rcsParams?.location?.longitude) {
            contentPattern = {
              recipient_type: 'individual',
              type: 'location',
              location: { ...rcsParams.location },
            };
          } else {
            saveAllowed = false;
            toast.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_GEOLOCATION_REQUIREMENTS'));
          }
          break;
        case 'whatsAppTemplate': {
          const { namespace } = rcsParams;
          let componentsVariables = [];
          if (rcsParams?.componentsParams) {
            componentsVariables = Object.keys(rcsParams.componentsParams)
              .map(componentKey => ({
                type: componentKey,
                parameters: Object.values(rcsParams.componentsParams[componentKey])
                  .map(variablesValue => ({
                    type: 'text',
                    text: variablesValue,
                  })),
              }));
          }
          const templateComponents = rcsParams.components.map((I) => {
            if (I.text) {
              const text = I.text.replace(/{{\d}}/g, (string) => {
                const variableValue = rcsParams.components?.[I.type]?.[string.replace(/{{/g, '').replace(/}}/g, '')];

                return variableValue || string;
              });
              return ({
                ...I,
                text,
              });
            }

            return I;
          });

          if (mediaFormat) {
            const header = {
              type: 'header',
              parameters: [],
            };
            if (rcsParams?.image?.fileUrl || rcsParams?.location) {
              switch (mediaFormat) {
                case 'image':
                  header.parameters[0] = ({
                    type: 'image',
                    image: {
                      link: rcsParams.image.fileUrl,
                    },
                  });
                  break;
                case 'video':
                  header.parameters[0] = ({
                    type: 'video',
                    video: {
                      link: rcsParams.image.fileUrl,
                    },
                  });
                  break;
                case 'document':
                  header.parameters[0] = ({
                    type: 'document',
                    document: {
                      link: rcsParams.image.fileUrl,
                    },
                  });
                  break;
                case 'location':
                  header.parameters[0] = ({
                    type: 'location',
                    location: { ...rcsParams.location },
                  });
                  break;
                default: break;
              }
            } else {
              saveAllowed = false;
              toast?.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_FILE_REQUIREMENTS'));
            }

            componentsVariables.unshift(header);
          }
          contentPattern = {
            type: 'template',
            template: {
              namespace,
              language: {
                policy: 'deterministic',
                code: rcsParams.language,
              },
              name: rcsParams.name,
              templateComponents,
              components: componentsVariables,
            },
          };
          let variablesCount = 0;
          rcsParams.components.forEach((item) => {
            if (item.type === 'body' || item.type === 'header') {
              const result = item.text?.match(/{{\d}}/g);
              if (result) {
                variablesCount += result.length;
              }
            }
          });
          let componentsVariablesCount = 0;
          componentsVariables.forEach((item) => {
            item.parameters.forEach((parameter) => {
              if (parameter.type === 'text' && parameter.text) {
                componentsVariablesCount += 1;
              }
            });
          });
          if (variablesCount && (variablesCount !== componentsVariablesCount)) {
            saveAllowed = false;
            toast?.error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_TEMPLATE_FIELDS'));
          }
          break;
        }
        default:
          saveAllowed = false;
          toast?.error(t('NEW_TRANSLATES.TOAST_ERRORS.SELECT_MESSAGE_TYPE'));
          break;
      }
      if (saveAllowed) {
        onChange({
          index,
          channelType: 4,
          contentPattern,
          deliveryCondition: waitFor,
          order: 0,
          senderId: sender,
          timeout: convertObjectTimeToSeconds(waitingTime),
        });
      }
    }
  };
  const onGeolocationSubmit = (values) => {
    updateRcsParams({ location: { ...values } });
    toggleGeolocationOpen();
  };
  const onChangeMessageType = (value) => {
    updateRcsParams({ isReset: true });
    setApprovedTemplate('');
    setFreeMessage('');
    setSelectedTemplate(null);
    setMessageType(value);
  };
  const onChangeWhatsAppTemplate = (id, componentsParams = {}, mode) => {
    let templateParams = {};
    const currentMessageType = mode || messageType;

    if (id) {
      templateParams = (currentMessageType.value === 2
        ? approvedTemplates
        : templates
      ).find(I => I.id === id);
      setSelectedTemplate(id);
    }
    if (currentMessageType.value === 2) {
      setSender(templateParams.senderId);
      updateRcsParams({
        isReset: true,
        selectedType: 'whatsAppTemplate',
        ...cloneDeep(templateParams),
        ...cloneDeep(componentsParams),
      });
    } else {
      const templateContent = templateParams?.content ? JSON.parse(templateParams?.content) : {};
      let channelCopy = {
        ...templateContent,
        ...componentsParams,
      };
      if (templateContent.interactive) {
        channelCopy = { ...templateContent.interactive };
      }
      if (!(templateContent.interactive?.action?.sections || channelCopy.action?.sections) && !channelCopy.suggestionsInside) {
        channelCopy.suggestionsInside = channelCopy.action?.buttons?.map(I => ({
          id: I.reply.id,
          text: I.reply.title,
          suggestionType: I.type,
        }));
        delete channelCopy.action;
      }
      updateRcsParams({ isReset: true, ...channelCopy });
    }
  };
  const onChangeSender = (value) => {
    if (value) {
      setShowError(false);
    }
    setSender(value);
  };
  const onSaveTemplate = () => {
    let templateType;
    let saveAllowed = true;
    let rcsParamsCopy = { ...rcsParams };

    switch (rcsParams.selectedType) {
      case 'whatsAppMenu':
        if (!rcsParams?.body?.text || !rcsParams?.action?.sections.length) {
          saveAllowed = false;
          error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_LIST_REQUIREMENTS'));
        }
        templateType = 'List';
        rcsParamsCopy = {
          recipient_type: 'individual',
          type: 'interactive',
          interactive: {
            type: 'list',
            ...rcsParamsCopy,
          },
        };
        break;
      case 'whatsAppCard':
        if (rcsParams?.body?.text && (rcsParams?.suggestionsInside && rcsParams?.suggestionsInside.length)) {
          const copyOfSuggestions = rcsParamsCopy.suggestionsInside;
          const buttons = copyOfSuggestions.map(I => ({
            type: 'reply',
            reply: {
              id: I.id,
              title: I.text,
            },
          }));
          rcsParamsCopy.action = { buttons };
          delete rcsParamsCopy.suggestionsInside;
          rcsParamsCopy = {
            recipient_type: 'individual',
            type: 'interactive',
            interactive: {
              type: 'button',
              ...rcsParamsCopy,
            },
          };
          templateType = 'Card';
        } else {
          saveAllowed = false;
          error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_CARD_REQUIREMENTS'));
        }
        break;
      case 'whatsAppText':
        if (!rcsParams?.text?.body) {
          saveAllowed = false;
          error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_TEXT_REQUIREMENTS'));
        } else {
          templateType = 'Text';
          rcsParamsCopy = {
            recipient_type: 'individual',
            type: 'text',
            ...rcsParamsCopy,
          };
        }
        break;
      case 'whatsAppSendGeolocation':
        if (!rcsParams?.body?.text) {
          saveAllowed = false;
          error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_TEXT_REQUIREMENTS'));
        } else {
          templateType = 'Location';
          rcsParamsCopy = {
            recipient_type: 'individual',
            type: 'interactive',
            interactive: {
              ...rcsParamsCopy,
            },
          };
        }

        break;
      case 'whatsAppGetGeolocation':
        if (!rcsParams?.location?.latitude && !rcsParams?.location?.longitude) {
          saveAllowed = false;
          error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_GEOLOCATION_REQUIREMENTS'));
        } else {
          templateType = 'Location';
          rcsParamsCopy = {
            recipient_type: 'individual',
            type: 'location',
            ...rcsParamsCopy,
          };
        }
        break;
      case 'whatsAppFile':
        if (!rcsParams?.image
            && !rcsParams?.document
            && !rcsParams?.video
            && !rcsParams?.audio
        ) {
          saveAllowed = false;
          error(t('NEW_TRANSLATES.TOAST_ERRORS.WHATSAPP_FILE_REQUIREMENTS'));
        } else {
          templateType = 'File';
          let type = 'document';
          if (rcsParams?.image) {
            type = 'image';
          }
          if (rcsParams?.video) {
            type = 'video';
          }
          if (rcsParams?.audio) {
            type = 'audio';
          }
          rcsParamsCopy = {
            recipient_type: 'individual',
            type,
            ...rcsParamsCopy,
          };
        }
        break;
      default:
        saveAllowed = false;
        error(t('NEW_TRANSLATES.TOAST_ERRORS.SELECT_MESSAGE_TYPE'));
        break;
    }

    if (saveAllowed) {
      const templateParams = typeof freeMessage.value === 'number' ? templates.find(I => I.id === selectedTemplate) : {
        channel: 4,
        name: freeMessage.value,
      };
      const templateBody = {
        channel: templateParams.channel,
        content: JSON.stringify(rcsParamsCopy),
        name: templateParams.name,
        type: templateType,
      };
      if (freeMessage.isCustom) {
        postCreateTemplate(templateBody)
          .then(() => {
            getTemplates({ limit: 1000 });
          });
      } else {
        patchUpdateTemplate({
          ...templateBody,
          id: freeMessage.value,
        }).then(() => {
          getTemplates({ limit: 1000 });
        });
      }
    }
  };
  const onDeleteTemplate = () => {
    deleteTemplate(selectedTemplate)
      .then(() => {
        setSelectedTemplate(null);
        setFreeMessage('');
        updateRcsParams({ isReset: true });
        getTemplates({
          limit: 1000,
        });
      });
  };

  const getConfigInput = () => (messageType.value === 2 ? {
    value: approvedTemplate,
    onChange: setApprovedTemplate,
  } : {
    value: freeMessage,
    onChange: setFreeMessage,
  });

  useEffect(() => {
    const idTemplate = messageType.value === 2 ? approvedTemplate.value : freeMessage.value;
    if (typeof idTemplate === 'number') {
      onChangeWhatsAppTemplate(idTemplate);
    } else if (messageType.value === 1) {
      onChangeWhatsAppTemplate(undefined, rcsParams);
    }
  }, [freeMessage, approvedTemplate]);

  useEffect(() => {
    if (!isEmpty(approvedTemplate)) {
      const foundItem = approvedTemplatesOptions.find(I => I.title === approvedTemplate.value
          || I.id === freeMessage.value);

      if (foundItem) {
        setApprovedTemplate({ value: foundItem.id, isCustom: false });
      } else {
        setApprovedTemplate({});
      }
    }
    if (!isEmpty(freeMessage)) {
      const foundItem = templatesOptions.find(I => I.title === freeMessage.value
          || I.id === freeMessage.value);

      if (foundItem) {
        setFreeMessage({ value: foundItem.id, isCustom: false });
      } else {
        setFreeMessage({});
      }
    }
  }, [approvedTemplatesOptions, templatesOptions]);
  useEffect(() => {
    if (channel
        && !channel.name
        && !rcsParams?.text?.body
        && !rcsParams?.interactive?.header?.text
        && !rcsParams?.interactive?.body?.text
        && !rcsParams?.interactive?.footer?.text
    ) {
      updateRcsParams({ isReset: true, ...channel });
    }
  }, [channel]);
  useEffect(() => {
    if ((channel?.selectedType === 'whatsAppSendGeolocation'
            || channel?.selectedType === 'whatsAppCard'
            || channel?.selectedType === 'whatsAppText'
            || channel?.selectedType === 'whatsAppFile'
            || channel?.selectedType === 'whatsAppMenu')
        && templates.length
    ) {
      setMessageType({ value: 1, isCustom: false });
      onChangeWhatsAppTemplate(
        channel?.templateId,
        channel,
        { value: 1, isCustom: false },
      );
    } else if (channel?.name && approvedTemplates.length && senders.length) {
      setMessageType({ value: 2, isCustom: false });
      const approvedTemplateId = approvedTemplates.find(I => I.name === channel.name)?.id;
      if (approvedTemplateId) {
        setApprovedTemplate({ value: approvedTemplateId, isCustom: false });
        onChangeWhatsAppTemplate(
          approvedTemplateId,
          channel,
          { value: 2, isCustom: false },
        );
      } else if (!toastClass.isActive('deletedTemplate')) {
        toast.error(t('ERRORS.DELETED_WHATS_APP_TEMPLATE'), { toastId: 'deletedTemplate' });
      }
    }
  }, [channel?.name, approvedTemplates, templates, senders]);
  useEffect(() => {
    getSenders({ limit: 1000, onlyActiveSenders: false });
    getAttributes({ variablesOnly: true });
    getApprovedTemplates({
      limit: 1000,
    });
    getTemplates({
      limit: 1000,
    });
  }, []);

  if (isPendingGetTemplates || isPendingGetSenders || isPendingGetAttributes) {
    return <LoadingSpinner fullSize />;
  }

  return (
    <div className="whats-app-block">
      <h2 className="whats-app-block__header">
        {t('SCREENS.BROADCASTS.WHATSAPP_MESSAGE')}
      </h2>
      <div style={{ display: 'flex' }}>
        <div className="whats-app-block__dropdown-fields">
          <div className="whats-app-block__dropdown-specify-field">
            <span className="dropdown-specify-field__label">
              {t('SCREENS.BROADCASTS.TYPE')}
            </span>
            <DropdownSpecifyField
              input={{
                value: messageType,
                onChange: onChangeMessageType,
              }}
              items={messageTypes}
              style={{ width: 316 }}
              disabled={readOnly}
            />
          </div>
          <div className="whats-app-block__dropdown-specify-field">
            <span className="dropdown-specify-field__label">
              {t('SCREENS.BROADCASTS.TEMPLATE')}
            </span>
            <DropdownSpecifyField
              input={getConfigInput()}
              placeholder={messageType.value === 2 ? '' : t('SCREENS.BROADCASTS.TEMPLATE')}
              items={messageType.value === 2 ? approvedTemplatesOptions : templatesOptions}
              style={{ width: 316 }}
              disabled={readOnly}
            />
          </div>
        </div>
        <div className="whats-app-block__btn-icon">
          <IconButton
            icon={disketteDarkIcon}
            onClick={onSaveTemplate}
            isDisabled={isEmpty(freeMessage) || (messageType.value === 2) || readOnly}
            filterType="white"
          />
          <IconButton
            icon={trashIcon}
            onClick={onDeleteTemplate}
            isDisabled={isEmpty(freeMessage) || freeMessage.isCustom || (messageType.value === 2) || readOnly}
            filterType="white"
          />
        </div>
      </div>
      <div className={wrapperClass}>
        <div className={wrapperHeaderClass}>
          <BasicButton
            onClick={resetType}
            disabled={readOnly}
            style={headerButtonStyle}
            icon={rcsParams.selectedType && rcsParams.selectedType !== 'whatsAppTemplate' && arrowLeftIcon}
          />
          {
            withTools && (
              <SendersDropdown
                values={sendersOptions}
                value={sender}
                onChange={onChangeSender}
                showError={showError}
                disabled={messageType.value === 2 || readOnly}
                isNewDesign={messageType.value === 1}
                channel="whats-app"
              />
            )
          }
        </div>
        {messageType.value === 1 && (
          <ConstructorEditorNew
            rcsParams={rcsParams}
            updateRcsParams={updateRcsParams}
            channel={channelNumber}
            openMediaSelect={toggleSelectOpenMedia}
            openGeolocation={toggleGeolocationOpen}
            readOnly={readOnly}
            openCreateSuggestion={openCreateSuggestion}
            withShortLink={withShortLink}
            setShortLinks={setShortLinks}
          />
        )}
        {messageType.value === 2 && (
          <ConstructorEditor
            rcsParams={rcsParams}
            updateRcsParams={updateRcsParams}
            constructorType="whatsApp"
            chooseTypeStyle={{
              gridTemplateColumns: '72px 72px 72px',
            }}
            openMediaSelect={toggleSelectOpenMedia}
            openGeolocation={toggleGeolocationOpen}
            selectedTemplate={selectedTemplate}
            readOnly={readOnly}
          />
        )}
        {
            messageType.value === 2 && !selectedTemplate && (
            <div className="whats-app-block__disabler" />
            )
        }
      </div>
      {
        withTools && (
          <>
            <div className="whats-app-block__time-tools" style={{ marginTop: 24 }}>
              <span className="whats-app-block__label">
                {t('SCREENS.BROADCASTS.WAIT_FOR')}
              </span>
              <DropdownField
                input={{
                  value: waitFor,
                  onChange: setWaitFor,
                }}
                type="grey-border"
                values={[
                  {
                    id: 'DELIVERY_SUCCESS',
                    title: t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.DELIVERY_SUCCESS'),
                  },
                  {
                    id: 'SUBMIT_SUCCESS',
                    title: t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.SUBMIT_SUCCESS'),
                  },
                  {
                    id: 'DISPLAY_SUCCESS',
                    title: t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.DISPLAY_SUCCESS'),
                  },
                ]}
                isDisabled={readOnly}
                withoutCallbackDestroy
              />
            </div>
            <div className="whats-app-block__time-tools">
              <span className="whats-app-block__label">
                {t('SCREENS.BROADCASTS.WAITING_TIME')}
              </span>
              <TimePickerField
                input={{
                  value: waitingTime,
                  onChange: setWaitingTime,
                }}
                styles={timeFieldStyle}
                typeOverlay="primary-color"
                withTimezone={false}
                disabled={readOnly}
              />
            </div>
          </>
        )
      }
      <div className="whats-app-block__buttons">
        <BasicButton
          text={t('CONTROLS.CANCEL')}
          type={BasicButton.types.CANCEL}
          icon={crossIcon}
          onClick={onCancel}
          isNewStyle
        />
        <BasicButton
          text={t('CONTROLS.SAVE')}
          type={BasicButton.types.ACCENT}
          icon={disketteIcon}
          onClick={onApply}
          disabled={
            !rcsParams.selectedType
            || (rcsParams.selectedType === 'whatsAppTemplate'
              && isEmpty(rcsParams.components))
            || readOnly
          }
        />
      </div>
      {
        createSuggestion.open && (
          <Drawer
            visible
            onClose={closeCreateSuggestion}
            width={500}
          >
            <CreateActionForm
              onClose={closeCreateSuggestion}
              onSubmit={onCreateSuggestion}
              initialValues={createSuggestion.initialValues}
              withPostback={false}
              maxLength={20}
              replyOnly
            />
          </Drawer>
        )
      }
      <SelectMedia
        open={selectMediaOpen}
        onClose={toggleSelectOpenMedia}
        onSelected={selectMedia}
        width={555}
        // mediaFormat={mediaFormat}
        format={templateExtensions}
        withUploadButton
      />
      <Drawer
        visible={geolocationOpen}
        onClose={toggleGeolocationOpen}
        width={480}
        destroyOnClose
      >
        <GeolocationForm
          onSubmit={onGeolocationSubmit}
          onClose={toggleGeolocationOpen}
          initialValues={geolocationInitialValues}
        />
      </Drawer>
    </div>
  );
};

export default WhatsAppBlock;
