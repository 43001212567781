// import transformMessage from './transformMessage';

export default ({ contentPattern }) => {
  if (contentPattern) {
    switch (true) {
      case contentPattern.type === 'location':
        return ({
          selectedType: 'whatsAppGetGeolocation',
          location: {
            longitude: contentPattern.location.longitude,
            latitude: contentPattern.location.latitude,
            name: contentPattern.location.name,
            address: contentPattern.location.address,
          },
        });
      case contentPattern.type === 'template': {
        const content = contentPattern.template.components.reduce((acc, item) => {
          if (item.type === 'header' && item.parameters[0].type !== 'text') {
            switch (item.parameters[0].type) {
              case 'image':
              case 'video':
              case 'document':
                acc.image = {
                  fileUrl: item.parameters[0][item.parameters[0].type].link,
                  fileMimeType: item.parameters[0].fileMimeType,
                  fileName: item.parameters[0].fileName,
                  fileSize: item.parameters[0].fileSize,
                  thumbnailUri: item.parameters[0].thumbnailUrl,
                };
                break;
              case 'location':
                acc = {
                  ...acc,
                  location: {
                    ...item.parameters[0].location,
                  },
                };
                break;
              default: break;
            }
          } else {
            const variablesObj = {};

            item.parameters.forEach((P, index) => {
              variablesObj[index + 1] = P.text;
            });

            acc.componentsParams = {
              ...acc.componentsParams,
              [item.type]: variablesObj,
            };
          }
          return acc;
        }, {});
        return ({
          name: contentPattern.template.name,
          ...content,
        });
      }
      case contentPattern.type === 'text':
      case contentPattern.selectedType === 'whatsAppText': {
        const channelCopy = { ...contentPattern };
        return ({ selectedType: 'whatsAppText', ...channelCopy });
      }
      case contentPattern.type === 'image':
      case contentPattern.type === 'document':
      case contentPattern.type === 'audio':
      case contentPattern.type === 'video':
      case contentPattern.selectedType === 'whatsAppFile':
        return ({ selectedType: 'whatsAppFile', ...contentPattern });
      case contentPattern.interactive
        && contentPattern.interactive.selectedType === 'whatsAppMenu':
        return ({ ...contentPattern.interactive });
      case contentPattern.interactive
        && contentPattern.interactive.selectedType === 'whatsAppSendGeolocation':
      case contentPattern.interactive
        && contentPattern.interactive.selectedType === 'whatsAppCard': {
        const channelCopy = { ...contentPattern.interactive };
        channelCopy.suggestionsInside = channelCopy.action?.buttons?.map(I => ({
          id: I.reply.id,
          text: I.reply.title,
          suggestionType: I.type,
        }));
        delete channelCopy.action;
        return ({ ...channelCopy });
      }
      default:
        return {};
    }
  }

  return null;
};
