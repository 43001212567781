import React from 'react';
import BoomwareTwoFactorScreen from 'mkit-front-ds/dist/screens/BoomwareTwoFactorScreen';

import './style.scss';

const TwoFAVoiceScreen = () => (
  <div className="two-fa-voice-screen-container">
    <BoomwareTwoFactorScreen />
  </div>
);

export default TwoFAVoiceScreen;
