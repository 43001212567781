import React from 'react';
import classNames from 'classnames';

import { useDropdownLogic } from 'hooks';

import './style.scss';

const MenuDropdown = ({
  menu,
  children,
  disabled,
}) => {
  const {
    buttonRef,
    dropdownRef,
    isDropdownOpen,
    setDropdownOpen,
    toggleDropdown,
  } = useDropdownLogic();

  return (
    <button
      className="menu-dropdown"
      type="button"
      onClick={toggleDropdown}
      ref={buttonRef}
      disabled={disabled}
    >
      {children}
      {
        isDropdownOpen && (
          <div
            className="menu-dropdown__overlay"
            ref={dropdownRef}
          >
            {
              menu.map(({
                text, action, icon, filterType, styleIcon,
              }) => (
                <div
                  className="menu-dropdown__overlay__option"
                  role="presentation"
                  key={text}
                  onClick={() => {
                    action();
                    setDropdownOpen(false);
                  }}
                >
                  {
                    icon && (
                      <img
                        src={icon}
                        alt=""
                        style={styleIcon}
                        className={classNames('menu-dropdown__overlay__option__img', {
                          [`menu-dropdown__overlay__option__img_filter-${filterType}`]: filterType,
                        })}
                      />
                    )
                  }
                  {text}
                </div>
              ))
            }
          </div>
        )
      }
    </button>
  );
};

export default MenuDropdown;
