import React from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { BasicButton } from 'components/buttons';
import { BasicField } from 'components/fields';

import './style.scss';

const GeolocationForm = ({
  onSubmit,
  initialValues,
  onClose,
}) => {
  const basicButtonStyle = {
    alignSelf: 'center',
    justifyContent: 'center',
  };
  const commonFieldStyle = {
    marginBottom: 12,
  };

  const { t } = useTranslation();

  const longitudeValidate = (value) => {
    if (value) {
      if (value < -180) {
        return t('ERRORS.LONGITUDE_MIN');
      }
      if (value > 180) {
        return t('ERRORS.LONGITUDE_MAX');
      }
      return undefined;
    }
    return t('ERRORS.REQUIRED');
  };
  const latitudeValidate = (value) => {
    if (value) {
      if (value < -90) {
        return t('ERRORS.LATITUDE_MIN');
      }
      if (value > 90) {
        return t('ERRORS.LATITUDE_MAX');
      }
      return undefined;
    }
    return t('ERRORS.REQUIRED');
  };

  const onSubmitHandler = ({ latitude, longitude, ...values }) => {
    onSubmit({
      longitude: Number(longitude),
      latitude: Number(latitude),
      ...values,
    });
  };

  return (
    <Form
      onSubmit={onSubmitHandler}
      initialValues={initialValues}
      render={({
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} className="geolocation-form">
          <h2 className="geolocation-form__title">
            {t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.SELECT_GEOLOCATION')}
          </h2>
          <Field
            name="latitude"
            component={BasicField}
            label={t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.LATITUDE')}
            styleWrapper={commonFieldStyle}
            parse={value => value || undefined}
            validate={latitudeValidate}
            type="with-border"
          />
          <Field
            name="longitude"
            component={BasicField}
            label={t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.LONGITUDE')}
            styleWrapper={commonFieldStyle}
            parse={value => value || undefined}
            validate={longitudeValidate}
            type="with-border"
          />
          <Field
            name="name"
            component={BasicField}
            label={t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.NAME')}
            styleWrapper={commonFieldStyle}
            type="with-border"
          />
          <Field
            name="address"
            component={BasicField}
            label={t('NEW_TRANSLATES.INSTANCES.MESSAGE_CONSTRUCTOR.ADDRESS')}
            styleWrapper={commonFieldStyle}
            type="with-border"
          />
          <div className="geolocation-form__buttons">
            <BasicButton
              text={t('CONTROLS.CANCEL')}
              type={BasicButton.types.PRIMARY}
              onClick={onClose}
              style={basicButtonStyle}
            />
            <BasicButton
              text={t('CONTROLS.SAVE')}
              type={BasicButton.types.ACCENT}
              onClick={handleSubmit}
              style={basicButtonStyle}
            />
          </div>
        </form>
      )}
    />
  );
};

export default GeolocationForm;
