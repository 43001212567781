import React from 'react';
import TwoFactorScreen from 'mkit-front-ds/dist/screens/TwoFactorScreen';

import './style.scss';

const TwoFASMSScreen = () => (
  <div className="two-fa-screen-container">
    <TwoFactorScreen />
  </div>
);

export default TwoFASMSScreen;
